/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@400,500,700&display=swap');

.font-satoshi {
  font-family: 'Satoshi', sans-serif;
}
/* Base styles */
:root {
  color-scheme: light dark;
}

body {
  @apply antialiased text-gray-900 dark:text-white;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-black/10 dark:bg-white/10 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-black/20 dark:bg-white/20;
}
/* User selection utilities */
.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.user-select-text {
  -webkit-user-select: text;
  user-select: text;
}

/* Optional: Style selected text */
.user-select-text::selection {
  background: rgba(59, 130, 246, 0.2); /* Light blue highlight */
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}
@layer utilities {
  .webkit-scrollbar {
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(156, 163, 175, 0.5);
      border-radius: 9999px;
    }
  }

  .scroll-smooth {
    scroll-behavior: smooth;
  }
}